import {
    Badge,
    Box,
    Button,
    Checkbox,
    Flex,
    useColorModeValue,
    Grid,
    GridItem,
    Stack,
    Text,
    useToast,
    Menu,
    MenuItem,
    MenuButton,
    Image,
    IconButton,
    MenuList,
} from "@chakra-ui/react";
import MoreIcon from "../../assets/svg/ic_more.svg";
import AddPropertyIcon from "../../assets/svg/ic_add_property.svg";
import CrossIcon from "../../assets/svg/ic_cross.svg";
import DragIcon from "../../assets/svg/ic_drag.svg";
import Fetch from "../../common/fetch";
import { FormC } from "../../common/validate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import InputField, { FormText } from "components/input";
import AddProperty from "components/template/addProperty";

const NewTemplate = (props) => {
    const toast = useToast();
    const history = useHistory();
    const [isOpen, setisOpen] = useState(false)
    const templateId = props.match.params?.id;
    const addForm = templateId === "add";
    const [isLoading, setisLoading] = useState(false);
    const [propertyList, setPropertyList] = useState([])
    const [filterPropertyList, setFilterPropertyList] = useState([]);
    const [staticList, setStaticList] = useState([]);
    const checkboxCOlor = useColorModeValue("gray.900", "white");

    useEffect(() => {
        fetchProperty()
    }, [])
    
    const fetchProperty = () => {
        Fetch('dashboard/template-property/').then(res => {
            if (res.status) {
                console.log(res.data, "res.data")
                setPropertyList(res?.data?.results)
                console.log(res?.data?.results)
                let selectedItems = [...state.properties]
                //  res.data.map((attr)=>{
                //     if(attr.universal_attribute){
                //         selectedItems.push(attr.id);
                //     }
                //  })
                
                //  setState({
                //     ...state,
                //     properties: selectedItems,
                // });
                setFilterPropertyList(res?.data?.results)
            }
        })
    }
    const [state, setState] = useState({
        name: "",
        description: "",
        properties: [],
        filtered_properties: []
    });


    const onSubmit = () => {
        setisLoading(true);
        Fetch(`dashboard/template/${addForm ? '' : templateId + '/'}`, state, { method: addForm ? "post" : 'put' }).then(res => {
            if (res.status) {
                toast({
                    title: `Template ${addForm ? 'created' : 'updated'}.`,
                    description: `We've ${addForm ? 'created' : 'updated'} your template successfully.`,
                    status: 'success',
                    duration: 3000,
                    isClosable: false,
                })
                setisLoading(false)
                setState({
                    name: "",
                    description: "",
                    properties: [],
                    filtered_properties: []
                });
                setTimeout(() => {
                    history.push({
                        pathname: "/admin/template",
                    });
                }, 3000)
            }
        })
    };

    const { handleSubmit, errors, handleNewError } = FormC({
        values: {
            name: state.name,
            description: state.description,
        },
        onSubmit,
    });
    useEffect(() => {
        if (!addForm) {
            Fetch(`dashboard/template/${templateId}/`).then((res) => {
                if (res.status) {
                    const { data } = res
                    let selectedItems = [...state.properties]
                    data.template.properties.map((attr)=>{
                           selectedItems.push(attr.id);
                       
                    })
                    setState({
                        ...state,
                        name: data.template.name,
                        description: data.template.description,
                         properties: selectedItems
                    })
                }
            });
        }

    }, []);
    const onChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };
    const handlePropertyModal = (hitProperty) => {
        setisOpen(!isOpen)
        if (hitProperty) {
            fetchProperty()
        }
    }
    const handleCheckbox = (e) => {
        const { value, checked } = e.target;
        let selectedItems = [...state.properties]
        if (checked) {
            selectedItems.push(value);
        } else {
            const index = selectedItems.indexOf(value);
            if (index > -1) {
                selectedItems.splice(index, 1);
            }
        }
        setState({
            ...state,
            properties: selectedItems,
        });
    }
    const handleChecked = (e) =>{
        const { value, checked } = e.target;
        let selectedItems = [...state.filtered_properties]
        if (checked) {
            selectedItems.push(value);
        } else {
            const index = selectedItems.indexOf(value);
            if (index > -1) {
                selectedItems.splice(index, 1);
            }
        }
        setState({
            ...state,
            filtered_properties: selectedItems,
        });
    }
    const onChangeSearch = (e) => {
        const data = propertyList.filter((item, key) => item.label?.toLowerCase().includes(e.target.value.toLowerCase()))
        setFilterPropertyList(data)
    }
    return (
        <Flex
            direction="column"
            pt={{ base: "120px", md: "75px", lg: "100px" }}
            p={"15px"}
            bgColor={"white"}
        >
            <form onSubmit={handleSubmit}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "50px",
                    }}
                >
                    <Stack direction="row" width={"fit-content"}>
                        <Badge
                            height={"28px"}
                            width={"110px"}
                            fontSize={"16px"}
                            textColor={"#898989"}
                            lineHeight={"32px"}
                            mr={"10px"}
                        >
                            Templates
                        </Badge>
                        <Text fontWeight={"bold"} fontSize={"20px"}>
                            {!addForm ? state.name : "New Object Template"}
                        </Text>
                    </Stack>
                    <Stack direction="row" spacing={4}>
                        <NavLink to="/admin/template">
                            <Button
                                variant="outline"
                                textColor={"#0584FE"}
                                borderColor={"#0584FE"}
                            >
                                Cancel
                            </Button>
                        </NavLink>
                        <Button isLoading={isLoading} type="submit" colorScheme="blue">
                            {!addForm ? "Update" : "Save"} & Close
                        </Button>
                    </Stack>
                </div>
                <Box
                    border="1px"
                    borderColor="gray.200"
                    p={"15px"}
                    mt={"15px"}
                    borderRadius={"8px"}
                >
                    <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                        <GridItem w='100%'>
                            <InputField
                                label='Template Name'
                                size="md"
                                borderRadius={"8px"}
                                fontSize="sm"
                                placeholder="Enter value"
                                name="name"
                                value={state.name}
                                onChange={onChange}
                                backgroundColor={"rgba(222, 222, 222, 0.3)"}
                                errorText={errors?.name}
                            />
                            <InputField
                                label='Template Description'
                                size="md"
                                borderRadius={"8px"}
                                fontSize="sm"
                                placeholder="Blank"
                                name="description"
                                type='textarea'
                                minH={"200px"}
                                value={state.description}
                                onChange={onChange}
                                backgroundColor={"rgba(222, 222, 222, 0.3)"}
                                errorText={errors?.description}
                            />
                        </GridItem>
                        <GridItem w='100%'>
                            <FormText label='Usage' />
                        </GridItem>
                    </Grid>
                </Box>
                <Box
                    border="1px"

                    borderColor="gray.200"
                    p={"15px"}
                    mt={"15px"}
                    borderRadius={"8px"}
                >
                    <Grid templateColumns='repeat(2, 1fr)' gap={3}>
                        <GridItem w='100%'>
                            <Flex alignItems='center' justifyContent='space-between'>
                                <FormText label='Select properties to add to Object Template' />
                                <Menu>
                                    <MenuButton _hover={{ bg: "transparent" }} minWidth='auto' _focus={{ boxShadow: 'unset' }} _active={{ bg: "transparent" }} bg="transparent" as={IconButton}>
                                        <Image src={MoreIcon} w={"20px"} />
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={handlePropertyModal}><Image src={AddPropertyIcon} w={"20px"} mr='10px' /> Add Property</MenuItem>
                                    </MenuList>
                                </Menu>
                            </Flex>
                            <InputField
                                size="md"
                                borderRadius={"8px"}
                                fontSize="sm"
                                placeholder="Search"
                                name="name"
                                onChange={onChangeSearch}
                            />
                            {
                                filterPropertyList?.map((items, key) => <><Checkbox
                                    _focus={{
                                        "& .chakra-checkbox__control": {
                                            boxShadow: "unset",
                                        }
                                    }}
                                    mb='20px'
                                    // _checked={{
                                    //     "& .chakra-checkbox__control": {
                                    //         background: "#AD26B4",
                                    //         borderColor: "#AD26B4"
                                    //     }
                                    // }}
                                    isChecked={state.properties.includes(items.id)}
                                    onChange={handleCheckbox}
                                    disabled = {items.universal_attribute}
                                    width={'100%'}
                                    value={items.id}
                                    size='lg'>
                                    <Text fontSize='14px' color={checkboxCOlor} width="100px" paddingTop="25px">{items.label}</Text>
                                    <Checkbox style={{marginLeft: "400px"}}  value={items.id} onChange={(e)=>handleChecked(e)}></Checkbox>

                                </Checkbox>

                                </>
                                )
                            }
                        </GridItem>
                        <GridItem w='100%'>
                            {state.properties?.length ? <Box borderRadius='10px' background='#F8F8F8' borderWidth='1px' borderColor='#C5D2D9' p={'24px'}>
                                <Text fontSize='18px' fontWeight='600'>Wine Product</Text>
                                {
                                    filterPropertyList.filter(items => state.properties.includes(items.id))?.map((items, key) => <Flex mt='15px'>
                                        <Image src={DragIcon} />
                                        <Box borderWidth='1px' w='100%' background='#fff' borderColor='#E2E8F0' p='8px' borderRadius='8px' display='flex' justifyContent='space-between'>{items.label} <IconButton _hover={{ background: 'transparent' }}   background='transparent' h='auto' disabled = {items.universal_attribute} onClick={()=>handleCheckbox({target:{value:items.id,checked:false}})}><Image src={CrossIcon} /></IconButton></Box>
                                    </Flex>)
                                }
                            </Box> : null}
                        </GridItem>
                    </Grid>
                </Box>
            </form>
            <AddProperty isOpen={isOpen} onClose={handlePropertyModal} />
        </Flex>
    );
};

export default NewTemplate;
