class auth {
    constructor() {
        this.token = "";
    }
    async getAsyncToken() {
        try {
            if (!this.token) {
                const user = localStorage.token;
                this.token = user;
            }
            return { token: this.token };
        } catch {
            this.token = this.token;
            // return this.signOut();
        }
    }
    isAuthenticated() {
        this.token = localStorage.token;
        if (this.token) {
            return true;
        } else {
            return false;
        }
    }
    removeStorage() {
        this.token=''
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
    }
}
const Auth = new auth();
export default Auth;