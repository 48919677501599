import { Th } from "@chakra-ui/react";
import { Td, Text, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
function DataTableHeadCell({ width, children, sorted, align, ...rest }) {
  const borderColor = useColorModeValue("gray.200", "gray.600");
  return (
    <Th
      borderColor={borderColor} fontSize='12px' color="gray.400" py={"18px"}
    >
        {children}
    </Th>
  );
}

// Setting default values for the props of DataTableHeadCell
DataTableHeadCell.defaultProps = {
  width: "auto",
  sorted: "none",
  align: "left",
};

// Typechecking props for the DataTableHeadCell
DataTableHeadCell.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node.isRequired,
  sorted: PropTypes.oneOf([false, "none", "asce", "desc"]),
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableHeadCell;
