import React from 'react';
import { Button, ButtonGroup, Text, Flex } from '@chakra-ui/react';

const Pagination = ({ currentPage, totalItems, itemsPerPage, onPageChange }) => {
  // Calculate total pages, but ensure at least 1 page exists if there are any items
  const totalPages = Math.max(Math.ceil(totalItems / itemsPerPage), 1);
  
  // Handle 'Previous' button click
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  // Handle 'Next' button click
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // Handle page button click
  const handlePageClick = (pageNumber) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber);
    }
  };

  // Generate an array of page numbers for navigation
  const getPageNumbers = () => {
    const pages = [];
    const maxPagesToShow = 5; // Number of page buttons to show
    let startPage = Math.max(2, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages - 1, startPage + maxPagesToShow - 1);

    // Adjust start page if near the end
    if (endPage - startPage < maxPagesToShow - 1) {
      startPage = Math.max(2, endPage - (maxPagesToShow - 1));
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  // Don't render pagination if there are no items
  if (totalItems === 0) {
    return null;
  }

  return (
    <Flex alignItems="center" justifyContent="end" mt={4}>
      <ButtonGroup spacing={2}>
        {/* Previous Button */}
        <Button onClick={handlePrevious} isDisabled={currentPage === 1}>
          Previous
        </Button>
        
        {/* First Page (Always show page 1) */}
        <Button
          onClick={() => handlePageClick(1)}
          variant={currentPage === 1 ? 'solid' : 'outline'}
          colorScheme={currentPage === 1 ? 'blue' : 'gray'}
          isDisabled={currentPage === 1}
          _focus={{ boxShadow: 'none' }} // Remove focus outline
        >
          1
        </Button>

        {/* Display "..." if the current page is far enough from page 1 */}
        {currentPage > 3 && <Text>...</Text>}

        {/* Middle Pages */}
        {getPageNumbers().map((page) => (
          <Button
            key={page}
            onClick={() => handlePageClick(page)}
            variant={page === currentPage ? 'solid' : 'outline'}
            colorScheme={page === currentPage ? 'blue' : 'gray'}
            _focus={{ boxShadow: 'none' }} // Remove focus outline
          >
            {page}
          </Button>
        ))}

        {/* Display "..." if the current page is far from the last page */}
        {currentPage < totalPages - 2 && <Text>...</Text>}
        
        {/* Last Page (Only show if totalPages > 1) */}
        {totalPages > 1 && (
          <Button
            onClick={() => handlePageClick(totalPages)}
            variant={currentPage === totalPages ? 'solid' : 'outline'}
            colorScheme={currentPage === totalPages ? 'blue' : 'gray'}
            isDisabled={currentPage === totalPages}
            _focus={{ boxShadow: 'none' }} // Remove focus outline
          >
            {totalPages}
          </Button>
        )}

        {/* Next Button */}
        <Button onClick={handleNext} isDisabled={currentPage === totalPages}>
          Next
        </Button>
      </ButtonGroup>
    </Flex>
  );
};

export default Pagination;
