// Chakra imports
import { FaPencilAlt } from "react-icons/fa";
import {
  Badge,
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Pagination from "common/Pagination";
import CardBody from "components/Card/CardBody.js";
import DeleteModal from "components/Modals/DeleteModal";
import Fetch from "../../common/fetch";
import React, { useEffect, useState, useRef } from "react";
import { PlusIco } from "components/Icons/menu";
import { useHistory, withRouter } from "react-router-dom";
import { FiltersIco } from "components/Icons/menu";
import { SearchIco } from "components/Icons/Catalog";
import DataTable from "components/DataTable";

function CatalogListing(props) {
  const history = useHistory()
  const color = useColorModeValue("blue.500", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const borderColorTD = useColorModeValue("gray.900", "white");
  const [catalogueData, setCatalogueData] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const toast = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const[search, setSearch] = useState('')
  const [debouncedValue, setDebouncedValue] = useState(search);
  const debounceTimeoutRef = useRef(null);
  const itemsPerPage = 10;

  const getCatlogueData = (page, input) => {
    Fetch(`dashboard/product-object/?page=${page}&search=${input}`).then((res) => {
      if(res.status){
        setCatalogueData(res?.data?.results?.map(items =>{return {...items,actions:items}}))
        setTotalItems(res?.data?.count); 

      }
    });
  };
  const processEmbed = () => {
    setisLoading(true);
    Fetch("dashboard/process-embeddings", null, { method: "post" }).then(
      (res) => {
        if (res.status) {
          setisLoading(false);
          toast({
            title: `Process Embeddings.`,
            description: `Process embeddings is completed.`,
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        } else {
          setisLoading(false);
        }
      }
    );
  };
  useEffect(() => {
    getCatlogueData(currentPage, debouncedValue);
  }, [currentPage, debouncedValue]);
  const handleDeleteRequest = (id) => {
    Fetch(`dashboard/product-object/${id}/`, null, { method: "delete" }).then(
      (res) => {
        if (res.status) {
          getCatlogueData(currentPage, debouncedValue);
          toast({
            title: "Catalogue Deleted.",
            description: "We've deleted your catalogue successfully.",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        }
      }
    );
  };
  const handleSearch = (e) => {
    const value = e.target.value;
    console.log(value,"value"); // For debugging
    setSearch(value);
    setCurrentPage(1); // Reset current page to 1 when search changes
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current); // Clear the previous timeout
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedValue(value); // Set debounced value after delay
    }, 500)
  };
  const columns = [
    { accessor: "id", Header: "DATA ID", width: 130 },
    { accessor: "object_name", Header: "Name", width: 130 },
    { accessor: "template", Header: "Template", width: 130 },
    {
      accessor: "categories", Header: "Categories", width: 160,
      Cell: (cellValues) => {
        return cellValues.value.map((items, key) => <Badge py='1.5' borderRadius={10} mr='1' mb='1' colorScheme={'yellow'} key={key}>
          {items.name}
        </Badge>)
      }
    },

    {
      accessor: "actions",
      Header: "Actions",
      width: 130,
      Cell: (cellValues) => {
        return (
          <Flex>
          <DeleteModal
            name={"Delete"}
            title={"Are you sure you want to delete this catalog?"}
            handleDelete={()=>handleDeleteRequest(cellValues.value.id)}
          />
          <Button
            variant="outline"
            color={color}
            borderColor={color}
            colorScheme="blue"
            onClick={() => handleUpdateRequest(cellValues.value.id)}
          >
            <FaPencilAlt color={color} />
          </Button>
        </Flex>
        );
      },
    },
  ];
  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/catalog/${id}`,
    });
  };
  return (

    <Box>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Flex p="6px 0px 22px 0px" justify={"space-between"}>
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          Catalog
        </Text>
        <Stack direction={"row"} spacing={4}>
          <InputGroup w={"200px"}>
            <InputRightElement pointerEvents="none">
              {/* <SearchIcon color="gray.300" /> */}
              <SearchIco />
            </InputRightElement>
            <Input
              type="text"
              placeholder="Search"
              textColor={"rgba(177, 177, 177, 1)"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"24px"}
              onChange={(e)=>handleSearch(e)}
            />
          </InputGroup>
        

          <Button
            leftIcon={<PlusIco />}
            onClick={() => props.history.push("/admin/catalog/add")}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            pl={"15px"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
          >
            NEW ITEM
          </Button>
    
          {/* <Button
            rightIcon={<ActionsIco />}
            colorScheme="#0584FE"
            variant="outline"
            width={"120px"}
            height={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            padding={"8px 14px 8px 14px"}
            gap={"10px"}
            fontWeight={700}
            fontSize={"14px"}
            lineHeight={"24px"}
            letterSpacing={"1%"}
            textAlign={"center"}
            textColor={"#0584FE"}
          >
            ACTIONS
          </Button> */}
        </Stack>
      </Flex>
      <CardBody
        border={"1px solid lightgrey"}
        padding={"15px"}
        borderRadius="5px"
        marginBottom="20px"
        p={0}
      >
        <DataTable table={{ columns: columns, rows: catalogueData }} path="/inbound/asn/create" pageName = "Catalog" />
      </CardBody>
      
      

    </Flex>

    <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={setCurrentPage}
      />   
      
    </Box>
  );
}

export default withRouter(CatalogListing);
