import { Td, Text, useColorModeValue } from "@chakra-ui/react";
import PropTypes from "prop-types";
function DataTableBodyCell({ noBorder, align, children }) {
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Td
      textAlign={align}
      borderColor={borderColor}
    >
      <Text fontSize={'14px'} color={textColor} pb=".5rem">
        {children}
      </Text>
    </Td>
  );
}

// Setting default values for the props of DataTableBodyCell
DataTableBodyCell.defaultProps = {
  noBorder: false,
  align: "left",
};

// Typechecking props for the DataTableBodyCell
DataTableBodyCell.propTypes = {
  children: PropTypes.node.isRequired,
  noBorder: PropTypes.bool,
  align: PropTypes.oneOf(["left", "right", "center"]),
};

export default DataTableBodyCell;
