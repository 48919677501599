import {
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  InputGroup,
  InputRightElement,
  Input,
  useToast,
} from "@chakra-ui/react";
// Custom components
import { SearchIco } from "components/Icons/Catalog";

import CardBody from "components/Card/CardBody.js";
import Fetch from "../../common/fetch";
import React, { useEffect, useState, useRef } from "react";
import { ActionsIco } from "components/Icons/menu";
import { PlusIco } from "components/Icons/menu";
import {useHistory, withRouter } from "react-router-dom";
import Category from "components/Tables/Category";
import DataTable from "components/DataTable";
import Pagination from "common/Pagination";
import DataTableBodyCell from "components/DataTable/DataTableBodyCell";
import DeleteModal from "components/Modals/DeleteModal";
import { FaPencilAlt } from "react-icons/fa";

const CategoriesListing = (props) => {
  const history = useHistory()
  const toast = useToast();

  const textColor = useColorModeValue("gray.700", "white");
  const color = useColorModeValue("blue.500", "white");

  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [catagoriesListing, setCategoriesListing] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const[search, setSearch] = useState('')
  const [debouncedValue, setDebouncedValue] = useState(search);
  const debounceTimeoutRef = useRef(null);

  const itemsPerPage = 10;

  const fetchCategories = (page, value) => {

    Fetch(`dashboard/category/?page=${page}&search=${value}`).then((res) =>{                     setCategoriesListing(res?.data?.results.map(items => ({ ...items, actions: items })));
    setTotalItems(res?.data?.count);});
  };
  const handleUpdateRequest = (id) => {
    history.push({
      pathname: `/admin/categories/${id}`,
    });
  };
  const handleDeleteRequest = (id) => {
    Fetch(`dashboard/category/${id}`, null, { method: "delete" }).then(
      (res) => {
        if (res.status) {
          fetchCategories(currentPage, debouncedValue);
          toast({
            title: "Category Deleted.",
            description: "We've deleted your category successfully.",
            status: "success",
            duration: 3000,
            isClosable: false,
          });
        }
      }
    );
  };
  useEffect(() => {
    fetchCategories(currentPage, debouncedValue);
  }, [currentPage,debouncedValue]);
  const handleSearch = (e) => {
    const value = e.target.value;
    console.log(value, "value"); // For debugging
    setSearch(value);
    setCurrentPage(1); // Reset current page to 1 when search changes

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current); // Clear the previous timeout
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setDebouncedValue(value); // Set debounced value after delay
    }, 500);
  };

  const columns = [
    { accessor: "name", Header: "Name", width: 130 },
    { accessor: "description", Header: "Template Description", width: 130 },
    { accessor: "item_content", Header: "Item Content", width: 130 },
    { accessor: "modified_date", Header: "Last Modified", width: 130 },

    {
        accessor: "actions",
        Header: "Actions",
        width: 130,
        Cell: (cellValues) => 
          {
            return (
                <Flex>
                    <DeleteModal
                        name={"Delete"}
                        title={"Are you sure you want to delete this template?"}
                        handleDelete={() => handleDeleteRequest(cellValues.value.id)}
                    />
                    <Button
                        variant="outline"
                        color={color}
                        borderColor={color}
                        colorScheme="blue"
                        onClick={() => handleUpdateRequest(cellValues.value.id)}
                    >
                        <FaPencilAlt color={color} />
                    </Button>
                </Flex>
            );
        },
    },
];
  return (
    // <h1>Categories Page</h1>
    <Box>
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Flex p="6px 0px 22px 0px" justify={"space-between"}>
        <Text
          fontSize="xl"
          color={textColor}
          fontWeight="bold"
          //   backgroundColor={"yellow"}
        >
          Categories
        </Text>
        {/* <Badge colorScheme="#fdf40800">Categories</Badge> */}
        <Stack direction={"row"} spacing={4}>
        <InputGroup w={"200px"}>
                        <InputRightElement pointerEvents="none">
                            <SearchIco />
                        </InputRightElement>
                        <Input
                            type="text"
                            placeholder="Search"
                            textColor={"rgba(177, 177, 177, 1)"}
                            fontWeight={400}
                            fontSize={"14px"}
                            lineHeight={"24px"}
                            onChange={handleSearch}
                        />
                    </InputGroup>
          <Button
            leftIcon={<PlusIco />}
            onClick={() => props.history.push("/admin/categories/add")}
            variant="outline"
            width={"120px"}
            h={"40px"}
            borderRadius={"8px"}
            border={"1px solid #0584FE"}
            p={"8px 14px 8px 14px"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"24px"}
            textColor={"rgba(5, 132, 254, 1)"}
            borderColor={"rgba(5, 132, 254, 1)"}
          >
            NEW GROUP
          </Button>
        </Stack>
      </Flex>
      <CardBody
        border={"1px solid lightgrey"}
        padding={"15px"}
        borderRadius="5px"
        marginBottom="20px"
        p={0}
      >
                      <DataTable table={{ columns: columns, rows:  catagoriesListing  }} path="/inbound/asn/create" pageName = "Categories" />

      </CardBody>
    </Flex>
    <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={setCurrentPage}
      />   
    </Box>
  );
};

export default withRouter(CategoriesListing);
