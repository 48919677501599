import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";

function DeleteModal({ name, title, handleDelete }) {
  const color = useColorModeValue("red.500", "red.500");
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button variant='outline' color={color} colorScheme="red" onClick={onOpen} marginRight={"15px"}>
        <DeleteIcon/>
      </Button>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={"20px"}>{title}</ModalHeader>
          <ModalCloseButton />
          {/* <ModalBody>
            <Lorem count={2} />
          </ModalBody> */}
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                handleDelete();
                onClose();
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteModal;
