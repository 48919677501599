import {
    Flex, Heading, Image,
    Text,
    useColorModeValue,
    Box
} from "@chakra-ui/react";

export default function InfoCard({
    image,
    title,
    text,
    color
}) {
    const borderColor = useColorModeValue("gray.600", "gray.600")
    const bgColor = useColorModeValue("gray.300", "gray.300")
    return <Flex p={'8px'} mb={'10px'} borderRadius='8px' border={`1px solid`} borderColor={borderColor} bg={bgColor} alignItems='center'>
        {image ? <Image src={image} w={"42px"} /> : null}
        <Box ml='3'>
            <Heading as='h5' fontSize='9px' fontWeight={600} color={color} textTransform='uppercase'>{title}</Heading>
            <Text pt='4px' fontSize='12px'>{text}</Text>
        </Box>
    </Flex>
}