import React, { Component } from "react";
import CatalogListing from "views/Dashboard/CatalogListing";
import NewCatalog from "views/Dashboard/NewCatalog.js";
import SignIn from "views/Pages/SignIn.js";

import {
  HomeIco,
  CatalogIco,
  CategoriesIco,
  TranslationsIco,
  ObjectivesIco,
  ConversationsIco,
  UserExperiencesIco,
  StoresIco,
  AnalyticsIco,
  SettingsIco
} from "./components/Icons/menu";
import CategoriesListing from "views/Dashboard/CategoriesListing";
import NewCategory from "views/Dashboard/NewCategory";
import ForgotPassword from "views/Pages/ForgotPassword";
import Template from "views/template";
import NewTemplate from "views/template/addEdit";
import Settings from "views/setting/";

var dashRoutes = [
  {
    name: "",
    category: "account",
    state: "pageCollapse",
    views: [
      // {
      //   path: "/",
      //   name: "Home",
      //   icon: <HomeIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      {
        path: "/catalog",
        name: "Catalog Details",
        icon: <CatalogIco />,
        component: CatalogListing,
        layout: "/admin",
      },
      {
        path: "/catalog/:id",
        name: "Add Catalog",
        removeSidebar: true,
        component: NewCatalog,
        layout: "/admin",
      },
      {
        path: "/categories",
        name: "Categories",
        icon: <CategoriesIco />,
        component: CategoriesListing,
        layout: "/admin",
      },
      {
        path: "/categories/:id",
        name: "Add Category",
        removeSidebar: true,
        component: NewCategory,
        layout: "/admin",
      },
      {
        path: "/template",
        name: "Template",
        icon: <ObjectivesIco />,
        component: Template,
        layout: "/admin",
      },
      {
        path: "/template/:id",
        name: "Add Template",
        removeSidebar: true,
        component: NewTemplate,
        layout: "/admin",
      },
      {
        path: "/setting",
        name: "Settings",
        icon: <SettingsIco />,
        component: Settings,
        layout: "/admin"
      },
      // {
      //   path: "/",
      //   name: "Translations",
      //   icon: <TranslationsIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Objectives",
      //   icon: <ObjectivesIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Conversations",
      //   icon: <ConversationsIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "User Experiences",
      //   icon: <UserExperiencesIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Nodes",
      //   icon: <StoresIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      // {
      //   path: "/",
      //   name: "Analytics",
      //   icon: <AnalyticsIco/>,
      //   component: null,
      //   layout: "/admin",
      // },
      {
        path: "/signin",
        name: "Sign In",
        component: SignIn,
        layout: "/auth",
      },
      {
        path: "/reset-password/:id",
        name: "Forgot Password",
        component: ForgotPassword,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
