import {
  Flex,
  Stack,
  Button,
  Text,
  FormControl,
  Box,
  useToast,
  Grid,
  GridItem,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InputField from "components/input";
import { FormC } from "../../common/validate";
import Fetch from "../../common/fetch";
import InfoIcon from "../../assets/svg/info.svg";

const Settings = (props) => {
  const toast = useToast();

  const [state, setState] = useState({
    session_end: "",
    session_end_timer: "",
    follow_up_message_time: "",
    frame_to_send_greet: "",
    greet_text_message: ""
  });
  const [isLoading, setisLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'number' && name!=='threshold') {
      if (value.includes('.')) {
        return;
      }
    }
    
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    setisLoading(true);
    Fetch(`dashboard/session-settings/`, state, { method: "post" }).then(
      (res) => {
        if (res.status) {
          toast({
            title: `Settings updated.`,
            description: `We've updated your Settings successfully.`,
            status: "success",
            duration: 3000,
            isClosable: false,
          });
          setisLoading(false);
        }
      }
    );
  };
  // Handle form submission
  const { handleSubmit, errors, handleNewError } = FormC({
    values: {
      session_end: state.session_end,
      threshold: state.threshold,
      follow_up_message_time: state.follow_up_message_time,
      frame_to_send_greet: state.frame_to_send_greet,
      greet_text_message: state.greet_text_message,
      follow_up_text_message: state.follow_up_text_message
    },
    onSubmit,
  });
  useEffect(() => {
    Fetch(`dashboard/session-settings/`).then((res) => {
      if (res.status) {
        const { data } = res;

        setState({
          id: data.id,
          session_end: data.session_end,
          threshold: data.threshold,
          follow_up_message_time: data.follow_up_message_time,
          frame_to_send_greet: data.frame_to_send_greet,
          greet_text_message: data.greet_text_message,
          follow_up_text_message: data.follow_up_text_message
        });
      }
    });
  }, []);
  console.log(state,"state.....")
  return (
    <Flex
      direction="column"
      pt={{ base: "120px", md: "75px", lg: "80px" }}
      bgColor={"white"}
      p="25px"
    >
      <form noValidate  onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "50px",
          }}
        >
          <Stack direction="row" alignItems={"center"} width={"fit-content"}>
            <Text fontWeight={"bold"} fontSize={"20px"}>
              <Flex>
                Settings
              </Flex>
            </Text>
          </Stack>
          <Stack direction="row">
            <Button isLoading={isLoading} type="submit" colorScheme="blue">
              Update & Close
            </Button>
          </Stack>
        </div>

        <Box
          border="1px"
          borderColor="gray.200"
          p={"15px"}
          mt={"15px"}
          borderRadius={"8px"}
        >
          <Stack spacing={20}>
            {/* User */}
            <Grid templateColumns="repeat(2, 1fr)">
              <GridItem w="90%" h="50%">

                <FormControl isInvalid={errors.session_end}>
                  <InputField
                    name="session_end"
                    value={state.session_end}
                    onChange={handleChange}
                    placeholder="Enter Session End"
                    label="Session End"
                    errorText={errors?.session_end}
                    type="number"
                    info="Defines the time between the follow-up message and when the session ends."
                  />

                </FormControl>
              </GridItem>
              <GridItem w="90%">
                <FormControl isInvalid={errors.threshold}>
                  <InputField
                    name="threshold"
                    value={state.threshold}
                    onChange={handleChange}
                    placeholder="Enter Threshold"
                    type="number"
                    label="Threshold"
                    size="md"
                    borderRadius={"8px"}
                    fontSize="sm"
                    noValidate
                    errorText={errors?.threshold}
                    info="Determine the value if the user is looking forward based on eye and face position. value range(0.01 to 0.05)"
                  />
                </FormControl>
              </GridItem>
              <GridItem w="90%">
                <FormControl isInvalid={errors.follow_up_message_time}>
                  <InputField
                    name="follow_up_message_time"
                    value={state.follow_up_message_time}
                    onChange={handleChange}
                    placeholder="Enter follow-up message time"
                    size="md"
                    type="number"
                    label="Follow-up Message Time"
                    borderRadius={"8px"}
                    fontSize="sm"
                    info='Specific time when a follow-up message is sent after the user stops engaging.'
                    errorText={errors?.follow_up_message_time}
                  />
                </FormControl>
              </GridItem>
              <GridItem w="90%">
                <FormControl isInvalid={errors.frame_to_send_greet}>
                  <InputField
                    name="frame_to_send_greet"
                    value={state.frame_to_send_greet}
                    onChange={handleChange}
                    placeholder="Enter frame to send greet"
                    label="Frame to Send Greet"
                    errorText={errors?.frame_to_send_greet}
                    type="number"
                    info="Determines how many times the user looked at the camera"
                  />
                </FormControl>
              </GridItem>
              <GridItem w="90%">
                <FormControl isInvalid={errors.greet_text_message}>
                  <InputField
                    name="greet_text_message"
                    value={state.greet_text_message}
                    onChange={handleChange}
                    placeholder="Enter Greeting Message"
                    label="Greeting Message"
                    errorText={errors?.greet_text_message}
                    type="text"
                    info ="The first message displayed on the UI when the user is recognized."
                  />
                </FormControl>
              </GridItem>
              <GridItem w="90%">
                <FormControl isInvalid={errors.follow_up_text_message}>
                  <InputField
                    name="follow_up_text_message"
                    value={state.follow_up_text_message}
                    onChange={handleChange}
                    placeholder="Enter Follow Up Message"
                    label="Follow Up Message"
                    errorText={errors?.follow_up_text_message}
                    type="text"
                    info="Reminder message when the user doesn't respond"
                  />
                </FormControl>
              </GridItem>
            </Grid>
          </Stack>
        </Box>
      </form>
    </Flex>
  );
};

export default Settings;
